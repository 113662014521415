@import './base.css';
/*.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
*/
/*

html, body {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  color: var(--color-text);
  background: var(--color-background);
  transition:
    color 0.5s,
    background-color 0.5s;
  line-height: 1.6;
  font-family:
    Inter,
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  font-size: 15px;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
  */

  *,
*::before,
*::after {
  box-sizing: border-box;
}

.container {
  max-width: 100%;
  overflow: hidden;
  margin: 0 auto;
}
.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  background-color: #eaeaea;
  background-image: url("data:image/svg+xml,%3Csvg width='84' height='84' viewBox='0 0 84 84' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23d1cabe' fill-opacity='0.4'%3E%3Cpath d='M84 23c-4.417 0-8-3.584-8-7.998V8h-7.002C64.58 8 61 4.42 61 0H23c0 4.417-3.584 8-7.998 8H8v7.002C8 19.42 4.42 23 0 23v38c4.417 0 8 3.584 8 7.998V76h7.002C19.42 76 23 79.58 23 84h38c0-4.417 3.584-8 7.998-8H76v-7.002C76 64.58 79.58 61 84 61V23zM59.05 83H43V66.95c5.054-.5 9-4.764 9-9.948V52h5.002c5.18 0 9.446-3.947 9.95-9H83v16.05c-5.054.5-9 4.764-9 9.948V74h-5.002c-5.18 0-9.446 3.947-9.95 9zm-34.1 0H41V66.95c-5.053-.502-9-4.768-9-9.948V52h-5.002c-5.184 0-9.447-3.946-9.95-9H1v16.05c5.053.502 9 4.768 9 9.948V74h5.002c5.184 0 9.447 3.946 9.95 9zm0-82H41v16.05c-5.054.5-9 4.764-9 9.948V32h-5.002c-5.18 0-9.446 3.947-9.95 9H1V24.95c5.054-.5 9-4.764 9-9.948V10h5.002c5.18 0 9.446-3.947 9.95-9zm34.1 0H43v16.05c5.053.502 9 4.768 9 9.948V32h5.002c5.184 0 9.447 3.946 9.95 9H83V24.95c-5.053-.502-9-4.768-9-9.948V10h-5.002c-5.184 0-9.447-3.946-9.95-9zM50 50v7.002C50 61.42 46.42 65 42 65c-4.417 0-8-3.584-8-7.998V50h-7.002C22.58 50 19 46.42 19 42c0-4.417 3.584-8 7.998-8H34v-7.002C34 22.58 37.58 19 42 19c4.417 0 8 3.584 8 7.998V34h7.002C61.42 34 65 37.58 65 42c0 4.417-3.584 8-7.998 8H50z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

.container {
  height: 100vh; /* Each container will take up the full screen height */

  /*padding: 20px;*/
  /*box-sizing: border-box;*/
  /*background-color: #DFDBE5;*/
}

.container-one {
  /*background-image: url('/src/assets/homeimage.png');*/
  /*background-image: url('/src/assets/homeimage.png');
  background-position: center;*/
  background-size: cover; 
  background-color: #26241c;
  display: flex;
  background-repeat: no-repeat;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 0px;
}

.container-one::before {
  content: "";
  position: absolute;
  top: 0;
  margin-top: 0px;
  left: 0;
  width: 100%;
  height: 50%;
  background-color: rgba(0, 0, 0, 0.318); /* Black with 50% opacity */
  z-index: 1; /* Ensure it's below the content */
}

.container-one > * {
  position: relative; /* Keep other elements above the overlay */
  z-index: 2;
}
.profile h3{
  font-size: 8rem;
  color: rgb(255, 255, 255);
  font-family:'Baskerville';
  font-weight: 600;
  margin-top: -100px;
  width: 1000px;
  opacity: 1;
  /*background-color: #d5d5cb;pretty*/
  background-color: #d7d7cf;
  background-image: url("data:image/svg+xml,%3Csvg width='84' height='84' viewBox='0 0 84 84' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23d1cabe' fill-opacity='0.4'%3E%3Cpath d='M84 23c-4.417 0-8-3.584-8-7.998V8h-7.002C64.58 8 61 4.42 61 0H23c0 4.417-3.584 8-7.998 8H8v7.002C8 19.42 4.42 23 0 23v38c4.417 0 8 3.584 8 7.998V76h7.002C19.42 76 23 79.58 23 84h38c0-4.417 3.584-8 7.998-8H76v-7.002C76 64.58 79.58 61 84 61V23zM59.05 83H43V66.95c5.054-.5 9-4.764 9-9.948V52h5.002c5.18 0 9.446-3.947 9.95-9H83v16.05c-5.054.5-9 4.764-9 9.948V74h-5.002c-5.18 0-9.446 3.947-9.95 9zm-34.1 0H41V66.95c-5.053-.502-9-4.768-9-9.948V52h-5.002c-5.184 0-9.447-3.946-9.95-9H1v16.05c5.053.502 9 4.768 9 9.948V74h5.002c5.184 0 9.447 3.946 9.95 9zm0-82H41v16.05c-5.054.5-9 4.764-9 9.948V32h-5.002c-5.18 0-9.446 3.947-9.95 9H1V24.95c5.054-.5 9-4.764 9-9.948V10h5.002c5.18 0 9.446-3.947 9.95-9zm34.1 0H43v16.05c5.053.502 9 4.768 9 9.948V32h5.002c5.184 0 9.447 3.946 9.95 9H83V24.95c-5.053-.502-9-4.768-9-9.948V10h-5.002c-5.184 0-9.447-3.946-9.95-9zM50 50v7.002C50 61.42 46.42 65 42 65c-4.417 0-8-3.584-8-7.998V50h-7.002C22.58 50 19 46.42 19 42c0-4.417 3.584-8 7.998-8H34v-7.002C34 22.58 37.58 19 42 19c4.417 0 8 3.584 8 7.998V34h7.002C61.42 34 65 37.58 65 42c0 4.417-3.584 8-7.998 8H50z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  padding: 4px;
  padding-bottom: 20px;
  text-align: center;
  /*display: inline-block;*/
  border: 1mm ridge;
  border-color: rgb(202, 197, 173);
  

}

.profile h4{
  font-size: 2rem;
  color: white;
  font-weight: lighter;
  margin-top: -120px;
  width: 700px;
  margin-left:50px;   
}
.container-two {
  /*background-color: rgb(255, 255, 255);*/
  /*background-position: center;*/
  display: flex;
  flex-direction: column;
  position: relative;
  box-sizing: border-box;
  /*background-color:white;*/
  align-items: center;
  justify-content: center;

  /*padding-top:200px;
  padding-bottom:300px;*/
  /*box-sizing: border-box;*/
  /*
  align-items: center;
  justify-content: center;
  text-align: center;*/


  /*align-items: center;
  justify-content: center;
  */ 

  background-position: center;
}






.about-me h1 {
  color:rgb(146, 156, 141);
  color:rgb(67, 61, 61);
  font-weight: bolder;
  font-family:'Baskerville';
  font-size:3rem;
  margin-top: 40px;
  text-align: center;

}  
.about-me-contents{
  background-color: rgb(255, 255, 255);
  background-color: #e6e6e6;
  width:1200px;
  border-radius: 20px;
  padding:40px;
  padding-bottom: 70px;
  border-width:2px;
  border-color:rgb(67, 61, 61);
  border-style: solid;
}
.upper-about-me{
  display:flex ;
  justify-content: center;
}
.upper-about-me img {
  height: 32rem;


  border-width: 5cm;
  border-color:#929c8d ;
  /*margin: auto;*/
  /*position:absolute;*/
  /*justify-content: center;
  align-items: center;*/
  opacity:0.9;
  /*border: 10px solid #051c1c;*/
  box-shadow: 1px 1px 1px 1px #cacaa9;
  /*margin-left: 220px;*/
}

.upper-about-me p {
  color:black;
 
  /*text-align: center;*/
  /*
  justify-content: center;
  align-items: center;
  */
  font-size:1rem;
  width: 750px; /* Ensures the text doesn’t overflow its container */
  margin-left: 20px;
  height:350px;
  margin-top:0px; 
  text-align: left;
 
  /*
  word-wrap: break-word;
  overflow: visible; 
  text-overflow: initial; 
  white-space: normal;*/

} 

@media (max-width: 1139px ) {
 /* .container-two {
     

  overflow: hidden; 
  box-sizing:border-box;
  }

  .container-two p{

    overflow: hidden; 
  text-overflow: ellipsis; /
  white-space: nowrap; 

  }
  */
  .upper-about-me p{
    font-size: 14px;
  }
     
}
.lower-about-me{
  margin-left: 0px;
  
}
/*
  .lower-about-me h2{
  color:#433d3d;
  font-size: 1.5rem;

  font-weight: light;
  position: relative;
  justify-content: left;
  margin-left: 27px;

  

}
  */
.lower-about-me p{
  font-size:0.7rem;

  border-radius: 2px;


  /*padding: 5px 5px 5px 5px;*/

  color:rgb(0, 0, 0);
  text-align: left;
  width: 400px;
  margin-top: 10px;
  margin-left: 22px;

  
  
  
}


/*.link {
  margin: 10px 0;
}
  */
.social-links{


  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: left;
  margin-left: 95px;
  gap:23px;
  margin-top: 20px;

}
.link1 a {
  text-decoration: none;
  color: #2c3e50;
  font-size: 16px;
  display: flex;
  align-items: center;

 
}

.link1 img {
  height: 3rem;
  opacity:0.8;
  
  
}

.link2 a {
  text-decoration: none;
  color: #2c3e50;
  font-size: 16px;
  display: flex;
  align-items: center;
 
 

}

.link2 img {
  height: 3rem;
  opacity:0.8;
}
.link3 a {
  text-decoration: none;
  color: #2c3e50;
  font-size: 16px;
  display: flex;
  align-items: center;
  
  

 
}

.link3 img {
  height: 3rem;
  opacity:0.8;
  
}

/*ProjectsCard css stuff below*/


.overall_wrapper{
  background-color: #eaeaea;
background-image: url("data:image/svg+xml,%3Csvg width='84' height='84' viewBox='0 0 84 84' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23d1cabe' fill-opacity='0.4'%3E%3Cpath d='M84 23c-4.417 0-8-3.584-8-7.998V8h-7.002C64.58 8 61 4.42 61 0H23c0 4.417-3.584 8-7.998 8H8v7.002C8 19.42 4.42 23 0 23v38c4.417 0 8 3.584 8 7.998V76h7.002C19.42 76 23 79.58 23 84h38c0-4.417 3.584-8 7.998-8H76v-7.002C76 64.58 79.58 61 84 61V23zM59.05 83H43V66.95c5.054-.5 9-4.764 9-9.948V52h5.002c5.18 0 9.446-3.947 9.95-9H83v16.05c-5.054.5-9 4.764-9 9.948V74h-5.002c-5.18 0-9.446 3.947-9.95 9zm-34.1 0H41V66.95c-5.053-.502-9-4.768-9-9.948V52h-5.002c-5.184 0-9.447-3.946-9.95-9H1v16.05c5.053.502 9 4.768 9 9.948V74h5.002c5.184 0 9.447 3.946 9.95 9zm0-82H41v16.05c-5.054.5-9 4.764-9 9.948V32h-5.002c-5.18 0-9.446 3.947-9.95 9H1V24.95c5.054-.5 9-4.764 9-9.948V10h5.002c5.18 0 9.446-3.947 9.95-9zm34.1 0H43v16.05c5.053.502 9 4.768 9 9.948V32h5.002c5.184 0 9.447 3.946 9.95 9H83V24.95c-5.053-.502-9-4.768-9-9.948V10h-5.002c-5.184 0-9.447-3.946-9.95-9zM50 50v7.002C50 61.42 46.42 65 42 65c-4.417 0-8-3.584-8-7.998V50h-7.002C22.58 50 19 46.42 19 42c0-4.417 3.584-8 7.998-8H34v-7.002C34 22.58 37.58 19 42 19c4.417 0 8 3.584 8 7.998V34h7.002C61.42 34 65 37.58 65 42c0 4.417-3.584 8-7.998 8H50z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");

}
.wrapper{
  position: relative;
}
.container-sep{
  padding:10px;
}
.projects-wrapper {
  display: flex;
  width: 100%;
  /*padding: 20px;*/
  
  flex-direction: column;
  position: relative;
  /*background-color: rgb(241, 242, 240);*/
  margin-top: 20px;
  gap: 10px;
  background-position: center;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  text-align: center;

}
/*
.about-me-contents{
  background-color: rgb(255, 255, 255);
  background-color: #e6e6e6;
  width:1200px;
  border-radius: 20px;
  padding:40px;
  padding-bottom: 70px;
  border-width:2px;
  border-color:rgb(67, 61, 61);
  border-style: solid;
}
*/
.projects-wrapper h1{
  color:rgb(67, 61, 61);
  font-family:'Baskerville';
  font-weight: bolder;
  font-size:3rem;
  display: flex;
  justify-content: center;

}


.project-card {
 
  display: flex;
  width: 1200px;
  /*max-width: 600px;  Limit the max width for better readability */
  /*background-color: #87765fbb;*/
  background-color: rgb(202, 199, 199);
  background-color: #252222;;
  border-width: 5px;
  border-color:black;
  background-position: center;
  padding:20px;
  border-radius: 20px;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  position: relative;

}

.project-card h2{
 
 display:flex;
 justify-content: center;
 width: 800px;
 margin-top:20px;
 color:rgb(255, 255, 255);
 font-size: 1.9rem;
 
}
.project-card h3{
 
  justify-content: center;
  width: 800px;
  color:rgb(255, 255, 255);
  font-size: 1.2rem;
  
 }


.top-part{
  display: flex;
  align-items: center;
  gap:20px;
  margin-top: 20px;
}
.top-part img {
  
  /* Make the image take up the full width */
  /* object-fit: cover; Ensure the image covers the space without distortion */
  height:400px;
  max-width:570px;
   /* Set a fixed width for the image */
   /* Maintain aspect ratio */
  margin-right: 20px; /* Space between image and text */
  margin-left: 20px;
  display: flex;

  
}
.top-part p {
  

  width: 450px;

  display: flex;
  color:white;
  text-align: right;
  font-size: 1.3rem;
  margin-top: 0px;


  
}

.links {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
}


.button1, .button2{
  display: inline-block;
  padding: 10px 80px;
  font-size: 16px;
  color: white;
  background-color: #2d3f31;
  background-color: #b48e56a8 ;
  background-color:#433d3d;
  text-align: center;
  text-decoration: none;
  border-radius: 5px;

}

/* Navbar css below */

.navigation {
  z-index: 3;
  display: flex;
  width: 99%;
  position: absolute;
  padding:10px;
  background-color: hsla(36, 31%, 6%, 0.26); 
  font-weight: light; 
  ul {
    display: flex;
    list-style: none;
    padding: 0;
    gap:60px;
    /*margin: 0 40px 0 0;*/
    margin: 12px auto; /* Center ul */
          justify-content: center; /* Center li items horizontally */
          align-items: center; /* Center li items vertically */

    li a{
      font-size: 1.2rem;
      font-weight: bold;
  
      color: rgb(189, 182, 172);
      padding: 2px 10px;
      cursor: pointer;
      text-decoration: none;

      &:hover {
        color: white;
      }
    }
  }

  
}
/*footer css below */

.footer{
  padding-bottom: 180px;
  background-color: #e6e6e6;
  padding:40px;
  padding-bottom: 70px;
  border-width:2px;
  border-color:rgb(67, 61, 61);
  border-style: solid;
  


}
.social-footer a{
  color:black;
  font-size:1.2rem;  
}
